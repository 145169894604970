import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Font,
} from "../../commons/Theme";

export const StyledMakeDifference = styled.div``;

export const StyledMakeDifferenceIntro = styled.div`
  padding-bottom: ${Rem(52)};
  text-align: center;

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(112)};
  }
`;

export const StyledMakeDifferenceIntroTitle = styled.h2`
  font-size: ${Rem(38)};
  line-height: ${Rem(42)};
  padding-bottom: ${Rem(24)};
  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(60)};
  }
`;

export const StyledMakeDifferenceIntroSubTitle = styled.h3`
  font-size: ${Rem(16)};
  line-height: ${Rem(28)};
  font-weight: 100;
  margin-bottom: 30px;
  @media (${BP.ipad}) {
    margin-bottom: 80px;
    font-size: ${Rem(30)};
    line-height: ${Rem(28)};
  }
`;

export const StyledMakeDifferenceGrid = styled.div`
  padding: ${Rem(54)} 0;

  @media (${BP.ipad}) {
    border-radius: 20px;
    padding: ${Rem(60)} ${Rem(20)} ${Rem(60)};
  }
`;

export const StyledMakeDifferenceGridTitle = styled.h4`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  padding-bottom: ${Rem(24)};
  text-transform: uppercase;
  
  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(15)};
  }
`;

export const StyledMakeDifferenceGridSubTitle = styled.p`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};
  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(45)};
    line-height: ${Rem(50)};
    padding-bottom: ${Rem(40)};
  }
`;

export const StyledMakeDifferenceGridItems = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
`;

export const StyledMakeDifferenceGridItem = styled.div`
  margin-bottom: ${Rem(40)};
  border-radius: 10px;
  background-color: ${(props) => props.color ? Colors[props.color] : Colors.yellow40};
  @media (${BP.ipad}) {
    position: relative;
    margin-bottom: 0;
    min-width: ${Rem(320)};
    max-width: ${Rem(320)};
    padding-bottom: ${Rem(60)};
  }
`;

export const StyledMakeDifferenceGridItemTitle = styled.h2`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  padding-bottom: ${Rem(16)};
  text-transform: uppercase;
`;

export const StyledMakeDifferenceGridItemParagraph = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(40)};
`;

export const StyledMakeDifferenceGridItemCta = styled.div`
  display: inline-block;

  @media (${BP.ipad}) {
    position: absolute;
    bottom: 20px;
    left: auto;
    right: auto;
  }
`;
