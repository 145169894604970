import React, { useContext } from "react";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import Spacer from "../components/Spacer/Spacer";
import SectionTwoCol from "../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../contexts/DataContext";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import BusinessSlider from "../components/BusinessSlider/BusinessSlider";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import BusinessClients from "../components/BusinessClients/BusinessClients";
import SingleVimeoPlayer from "../components/SingleVimeoPlayer/SingleVimeoPlayer";
import { StyledGridRow } from "../commons/Grid";
import BusinessCards from "../components/BusinessCards/BusinessCards";
import MakeDifference from "../components/MakeDifference/MakeDifference";
import Faq from "../components/Faq/Faq";
import useNavigate from "../hooks/useNavigate";
import {
  Button,
} from "@3beehivetech/components";

const Call4Beener = () => {
  const { 
    pageLabels, 
    pageImages,
    currentLanguageSuffix,
  } = useContext(DataContext);
  toast.configure();
  const items = pageLabels.what.items.map(e => {
    return {
      image: e.image,
      title: e.title,
      paragraph: e.paragraph,
      cta: e.cta,
    };
  });
  const grid = { title: pageLabels.what.title, subtitle: pageLabels.what.subtitle, items };

  return (
    <>
      <Meta />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": pageLabels.hero.title.replace(/<[^>]*>?/gm, ""),
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": pageLabels.hero.paragraph.replace(/<[^>]*>?/gm, ""),
            },
          ])}
        </script>
      </Helmet>
          
      <Hero3BC 
        title = {pageLabels.hero.title}
        image = "/2020/images/chi-siamo/team_3bee.jpg"
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#jobs"
        newsletterButtonText = {pageLabels.hero.newsletterButtonText}
        newsletterTitle = {pageLabels.hero.newsletterTitle}
        titleBackground = "transparent"
        lang={currentLanguageSuffix}
        success = "Welcome in 3Bee, Bzz"
        error="Error. Contact us on info@3bee.com"
        leftButtonProps={{
          text: pageLabels.hero.leftButtonProps.text,
          to: "#jobs",
        }}
        newsletterListType = "international"
        allImages = {pageImages}
      />
      
      <Spacer size={15}/>
      <MakeDifference
        grid = { grid }
        allImages = {pageImages}
      />
      <SectionTwoCol
        id="why"
        title={pageLabels.why.title}
        subtitle1={pageLabels.why.subtilte}
        cta={pageLabels.commons.askAdvice}
        to="#products"
        image="/2020/images/business/impact_background.jpg"
        topImage
      />
      
      <Spacer size={15}/>

      <Intro 
        id ="activities"
        bigText={pageLabels.activities.title}
        subtitle={pageLabels.activities.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/HR_icon.png",
          label: pageLabels.activities.education.label,
          text: pageLabels.activities.education.text,
        },{
          image: "/2020/images/business/CSR_RSI_icon.png",
          label: pageLabels.activities.beekeeper.label,
          text: pageLabels.activities.beekeeper.text,
        },{
          image: "/2020/images/business/communication_icon.png",
          label: pageLabels.activities.tasting.label,
          text: pageLabels.activities.tasting.text,
        }]}
      />
      
      <Spacer size={10}/>
      
      <StyledGridRow noMargin>
        <SingleVimeoPlayer
          videoID={pageLabels.who.vimeo}
          thumb="/2020/images/_MG_5712.jpg"
        />
      </StyledGridRow>

      <Spacer size={10}/>

      <Intro 
        bigText={pageLabels.who.title}
        subtitle={pageLabels.who.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/academy/impollination.svg",
          label: pageLabels.who.education.label,
          text: pageLabels.who.education.text,
        },{
          image: "/2020/images/business/academy/hive.svg",
          label: pageLabels.who.beekeeper.label,
          text: pageLabels.who.beekeeper.text,
        },{
          image: "/2020/images/business/academy/honey.svg",
          label: pageLabels.who.tasting.label,
          text: pageLabels.who.tasting.text,
        }]}
      />

      <Spacer size={10}/>

      <BusinessSlider items={pageLabels.slider.items.map(e => {
        return {
          ...e,
          theme: "white",
          cta: {
            label: pageLabels.slider.cta,
            url: "#jobs",
          },
          ul: e.items,
        };
      })}/>

      <Spacer size={20}/>

      <BusinessClients 
        title={pageLabels.clients.title}
        subtitle={pageLabels.clients.subtitle}
        logos={[
          { image: "/2020/images/business/academy/clients/amazon.png", slug: "amzl-italia-1" },
          { image: "/2020/images/business/academy/clients/calzedonia.png", slug: null },
          { image: "/2020/images/business/academy/clients/ubisoft.png", slug: null },
          { image: "/2020/images/business/academy/clients/davines.png", slug: "loasi-di-davines-1" },
          { image: "/2020/images/business/academy/clients/ferrero.png", slug: "ferrero" },
          { image: "/2020/images/business/academy/clients/nen.png", slug: "nen-1" },
          { image: "/2020/images/business/academy/clients/pernod.png", slug: "pride-beestrees-1" },
          { image: "/2020/images/business/academy/clients/toyota-new.png", slug: "tmhit-1" },
        ]}
      />

      <Spacer size={20}/>

      <Intro 
        id="jobs"
        bigText={pageLabels.cards.title}
        subtitle={pageLabels.cards.subtitle}
      />
      
      <BusinessCards
        cards = {pageLabels.cards.items}
      />

      <div style="display: flex; justify-content: center;">
        <Button onClick={useNavigate(pageLabels.apply.url)}>
          {pageLabels.apply.buttonCta}
        </Button>
      </div>

      <Spacer size={15}/>

      <Intro 
        id="timeline"
        bigText={pageLabels.timeline.title}
        subtitle={pageLabels.timeline.subtitle}
      />

      <BusinessIconText
        items={[{
          image: "/2020/images/business/honey.png",
          label: pageLabels.timeline.application.label,
          text: pageLabels.timeline.application.text,
        },{
          image: "/2020/images/business/ambassador.png",
          label: pageLabels.timeline.feedback.label,
          text: pageLabels.timeline.feedback.text,
        },{
          image: "/2020/images/business/beekeeper.png",
          label: pageLabels.timeline.interview.label,
          text: pageLabels.timeline.interview.text,
        }]}
      />

      <Spacer size={10}/>

      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Call4Beener;
