import React from "react";
import {
  StyledMakeDifference,
  StyledMakeDifferenceIntro,
  StyledMakeDifferenceIntroTitle,
  StyledMakeDifferenceIntroSubTitle,
  StyledMakeDifferenceGrid,
  StyledMakeDifferenceGridTitle,
  StyledMakeDifferenceGridSubTitle,
  StyledMakeDifferenceGridItems,
  StyledMakeDifferenceGridItem,
  StyledMakeDifferenceGridItemTitle,
  StyledMakeDifferenceGridItemParagraph,
  StyledMakeDifferenceGridItemCta,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import { Button } from "@3beehivetech/components";
import SingleVimeoPlayer from "../SingleVimeoPlayer/SingleVimeoPlayer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useNavigate from "../../hooks/useNavigate";

export default ({
  title, subtitle, grid, id = "howToAdopt", ...props 
}) => {
  const plan_color = ["ligthyellow", "pink", "greenlight"];
  const button_color = ["orange", "pink", "green"];
  //console.log(grid)
  return (
    <StyledMakeDifference>
      <StyledGridRow noMargin>
        {(props.title || props.subtitle || props.videoID) && (
          <StyledMakeDifferenceIntro>
            <StyledMakeDifferenceIntroTitle
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <StyledMakeDifferenceIntroSubTitle
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
            {props.videoID && (
              <SingleVimeoPlayer
                videoID={props.videoID}
                thumb="/2020/images/ape_tech.jpg"
              />
            )}
          </StyledMakeDifferenceIntro>
        )}
      </StyledGridRow>
      <StyledGridRow isFullMobile id={id}>
        <StyledMakeDifferenceGrid>
          <StyledGridRow noMargin>
            <StyledMakeDifferenceGridTitle
              dangerouslySetInnerHTML={{ __html: grid.title }}
            />
            <StyledMakeDifferenceGridSubTitle
              dangerouslySetInnerHTML={{ __html: grid.subtitle }}
            />
            <StyledMakeDifferenceGridItems>
              {grid.items.map((item, index) => {
                const imageData = props.allImages.find(
                  (e) => e.path === item.image,
                );
                return (
                  <StyledMakeDifferenceGridItem
                    key={index}
                    color={plan_color[index % 3]}
                  >
                    {imageData && (
                      <GatsbyImage
                        style={{ borderRadius: "10px 10px 0px 0px" }}
                        image={getImage(imageData)}
                        alt={imageData.alt}
                      />
                    )}
                    <div style="padding: 20px;">
                      <StyledMakeDifferenceGridItemTitle>
                        {item.title}
                      </StyledMakeDifferenceGridItemTitle>
                      <StyledMakeDifferenceGridItemParagraph dangerouslySetInnerHTML={{ __html: item.paragraph }}/>

                      {item.cta && (
                        <StyledMakeDifferenceGridItemCta>
                          <Button
                            variant={button_color[index % 3]}
                            onClick={useNavigate(item.cta.url)}
                          >
                            {item.cta.label}{" "}
                          </Button>
                        </StyledMakeDifferenceGridItemCta>
                      )}
                    </div>
                  </StyledMakeDifferenceGridItem>
                );
              })}
            </StyledMakeDifferenceGridItems>
          </StyledGridRow>
        </StyledMakeDifferenceGrid>
      </StyledGridRow>
    </StyledMakeDifference>
  );
};
